<template>

  <div style="height:100%">


    <v-layout row wrap style="height:100%">



      <v-flex xs12 md7 sm7 class="pdr">

        <v-card style="padding:45px;" flat>

         <div >
           
            <h1 style="text-align:right">اهلا وسهلا بكم في منصه  تكتاتي</h1>

          <br>


       
          <div v-if="!verfy_Dailog">
            <div style="color:#504a4a;font-size:18px;font-weight:bold">ادخل معلوماتك لانشاء حساب جديد في منصه  تكتاتي</div>


            


            <div style="color:#504a4a;font-size:13px">او قم بتجسيل الدخول اذا كنت تمتللك حساب

              <router-link to="/LoginOwner" text small color="red" style="font-weight:bold;font-size:18px">
                تسجيل الدخول

              </router-link>


            </div>
         </div>

            <v-form ref="form_signup" v-model="valid" v-if="is_signup">


              <v-layout row wrap>



                <v-flex xs12>




                  <v-flex xs12 md12 sm12 pt-5>
                    <v-alert type="error" v-if="error_msg.length!==0" style="position: relative;right: 0%;width: 84%;">

                      <span v-for="error in error_msg" :key="error">{{error}}</span>
                    </v-alert>


                  </v-flex>



                  <v-flex xs12 md10 sm10 pt-5>
                    <v-lable>الاسم</v-lable>
                    <v-text-field filled v-model="owners.name" height="50" style="" placeholder="الاسم" required
                      :rules="nameRules"></v-text-field>

                  </v-flex>

                  <v-flex xs12 md10 sm10>
                    <v-lable>اسم المركز</v-lable>
                    <v-text-field filled v-model="owners.company_name" height="50" style="" placeholder="اسم المركز"
                      required :rules="centerRules"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md10 sm10>
                    <v-lable>رقم الهاتف</v-lable>
                    <v-text-field filled v-model="owners.phone" height="50" style="" placeholder="رقم الهاتف"
                      type="number" required :rules="phoneRules"></v-text-field>
                  </v-flex>
                
                  <v-flex xs12 md10 sm10>
                    <v-lable>الباسورد</v-lable>
                    <v-text-field filled v-model="owners.password" height="50" style=""
                      :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'visibility' : 'visibility_off'"
                      @click:append="show1 = !show1" placeholder="الباسورد" required :rules="passwordRules">
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 md10 sm10>
                    <v-lable>اعاده كتابه الباسورد</v-lable>
                    <v-text-field filled v-model="owners.password_confirmation" :type="show2 ? 'text' : 'password'"
                      :append-icon="show2 ? 'visibility' : 'visibility_off'" @click:append="show2 = !show2" height="50"
                      style="" placeholder="اعاده كتابه الباسورد" required :rules="passwordConfirmationRules">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 md10 sm10>
                    <v-lable>القسم</v-lable>
                    <v-combobox filled :items="categories" item-text="name" item-value="id" height="50" style=""
                      v-model="owners.category_id" placeholder=" القسم" required :rules="CatsRules"></v-combobox>
                  </v-flex>



                  <!-- <v-flex xs12 md10 sm10>
                    <div style="padding:10px">يرجئ اضافه صور المركز </div>
                    <v-card height="height:100%">



                      <v-layout row wrap>

                        <v-flex xs12 md3 sm3 v-for="(img ,index) in images" pa-4 :key="img">
                          <v-btn icon style="position:relative;right:16px" @click='pickFile(index)'>
                            <v-icon style="margin:0px;" size="40">fas fa-plus-square</v-icon> اضافه
                          </v-btn>
                          <v-card height="90" width="90" class="card_img">


                            <v-img :src="img" height="90" width="90" class="card_img">
                            </v-img>




                          </v-card>
                        </v-flex>

                        <v-flex>
                          <input type="file" style="display: none" ref="image" accept="image/*" @change="onFilePicked">
                        </v-flex>


                      </v-layout>







                      <span style="display:none">{{img_name}}</span>

                    </v-card>

                  </v-flex> -->


                  <v-flex xs12 md12 sm12>
                    <br>
                    <v-btn class="btn btn-primary btn-block" color="red" @click="submitـsignup()"
                      style=";color:#fff;width:80%">
                      تسجيل</v-btn>

                  </v-flex>

                </v-flex>


              </v-layout>
            </v-form>



  
      <v-card  flat v-if="verfy_Dailog">
        <v-form v-model="active_form" ref="active_form">
          <v-container>

          <v-layout row wrap>
              <v-flex xs8>
                <p style="color:#000;font-size:19px;font-weight:bold">تم ارسال رقم التاكيد برساله يرجئ التحقق</p>
              </v-flex>

            <v-flex xs12 md8 sm8>
               <v-lable> رقم التاكيد</v-lable>
            <v-text-field filled  height="55" placeholder="ادخل رقم التاكيد" v-model="verfy_code" required counter-value="4" type="number"
              :rules="verfyRules" autocomplete="off" class="mb-6">
            </v-text-field>
            </v-flex>

          


              <v-flex xs6   >
                <v-btn color="success" @click="verfyAccount" style=";color:#fff;width:100%">
                  تفعيل
                </v-btn>
              </v-flex>

          </v-layout>
              <!-- <v-flex xs4>

                <v-btn color="red" @click="verfy_Dailog=false" style=";color:#fff;width:100%">
                  خروج
                </v-btn>


              </v-flex> -->

          </v-container>

        </v-form>
      </v-card>
   

            
          </div>


          <v-row>
            <v-text-field color="primary" loading disabled v-show="loading"></v-text-field>
          </v-row>

          <p style="color:red;text-align:center">{{this.errors}}</p>


        </v-card>

      </v-flex>


      <v-flex xs12 class="hidden-md-and-down" md5 sm5>
        <v-layout column class="search_component" style="height:100%;background-color: #F2C98A !important;">

          <div style="height: 100%;
    padding-top: 45px;

    position: fixed;
    bottom: 0;

    float: left;
    width: 40%;">


            <v-layout row wrap>

              <v-flex xs5></v-flex>

              <v-flex xs2>


                <v-img src="/assets/logo2.png" style="      height: 100%;
    width: 100%;"></v-img>




              </v-flex>


              <v-flex xs5></v-flex>

            </v-layout>
            <h2
              style="padding-top: 15px;width: 100%;text-align:center;font-weight:bold;font-size:30px;padding-right:15px;color:#986923">
               تكتاتي</h2>
            <v-row>
              <div
                style="width: 100%;text-align:center;color:#986923;font-size:20px;font-weight:bold;padding-top:10px;text-align: center;">
                حجوزات طبيه سياحيه مطاعم فنادق رياضه
              </div>
              <!-- <div  style="width: 100%;text-align:center;color:#986923;font-size:20px;font-weight:bold;padding-top:10px;text-align: center;padding:30px">
  منصة حجوزات هي منصة تمكن الزبائن من الحجز في الخدمات المسجلة لديها و تشمل خدمات كثيرة منها الحجوزات الطبية و الهندسية و حجوزات قاعات الاعراس و المؤتمرات و الخفلات و الندوات العلمية منصة حجوزات هي منصة تمكن الزبائن
</div> -->


            </v-row>
            <br>
            <br>


            <!-- <v-sheet class="mx-auto" style="background:none" dark="">
              <v-slide-group elevation="8" show-arrows dir="ltr">

                <v-slide-item v-for="item in images_com" :key="item.key" v-slot:default="{ active, toggle }" pa-5>
                  <v-card width="90" height="100"
                    style="box-shadow:none; margin-left:30px;margin-right:30px;background:none " dark pa-5>
                    <v-row justify="center">
                      <v-avatar height="100" width="100" color="white">
                        <v-img style="border:2px solid gray" :src="item.img" alt="John">
                        </v-img>
                      </v-avatar>
                    </v-row>
                    <v-spacer></v-spacer>
                    <v-row justify="center">
                      <v-card-title style="text-align:center">
                        شركة انجاز
                      </v-card-title>
                    </v-row>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
              
            </v-sheet> -->





            <v-layout row wrap style="padding-top: 40px;">

              <v-flex xs2></v-flex>

              <v-flex xs8>


                <v-img src="/assets/3899624.png" style="with:100%;height:100%"></v-img>




              </v-flex>


              <v-flex xs2></v-flex>

            </v-layout>






          </div>



        </v-layout>
      </v-flex>
    </v-layout>





  </div>


</template>

<style scoped>
  .v-content {
    padding: 0px 0px 0px 0px !important;
  }
</style>


<script>
  import localstorageservice from "./localstorageservice";
  import {
    //ADMIN_ROLE,
     OWNER_ROLE,
    // USER_ROLE_GUEST
    //Delivery_ROLE
  } from "../constants.js";
    import {
    EventBus
  } from '../event-bus.js';

  // import last_itemVue from '../../last_item.vue';

  const axios = require('axios');
  export default {
    $_veeValidate: {
      validator: 'new',
    },
    name: 'app',
    data: () => ({
      active_status: [],
      valid: true,
      is_signup:true,
      login_info: [],
      show1: false,
      show2: false,


      img_name: '',
      index: '',
      img_cliced: -1,
      signup: true,
      imageUrl: '',
  
      imageFile: '',
      categories: [],
      token: '',
      verfy_Dailog: false,
      active_form: false,
      owners: {
        name: "",
        password_confirmation: "",
        email: "",
        password: "",
        phone: "",
        company_name: "",
        category_id: "",
        item_num:""



      },
      error_msg: [],
      signin: false,
      images: [{
          "image_url": ''
        },
        {
          "image_url": ''
        },
        {
          "image_url": ''
        },
        {
          "image_url": ''
        }
      ],
      

      owner_info: {
        id: '',
        name: '',
        session: '',
        email: '',
        role: '',
        photo: '',
        password_confirmation: '',
        item_num:""
      },

      loading: false,
      nameRules: [
        v => !!v || 'يجب ادخال الاسم',
       // v => (v && v.length > 4) || 'يجب ان لايقل عن ٣ احرف',
      ],
      centerRules: [
        v => !!v || 'يجب ادخال اسم المركز',
     //   v => (v && v.length > 4) || 'يجب ان لايقل عن ٣ احرف',
      ],


      phoneRules: [
        (v) => !!v || 'يجب ادخال رقم الهاتف',
        (v) => v.length > 10 && v.length < 12 || 'يجب ادخال رقم هاتف صحيح',
        (v) => /^\d+$/.test(v) || 'يجب ادخال رقم هاتف صحيح',

      ],
      verfyRules: [
        (v) => !!v || 'يجب ادخال كود التفعيل',
        (v) => (v.length == 6) || ' يجب ان يكون كود التفعيل 6 ارقام',
        (v) => /^\d+$/.test(v) || 'ارقام فقط'

      ],

      CatsRules: [
        (v) => !!v || 'يجب ادخال القسم ',
        //(v) => v.length > 10 && v.length < 12 || 'يجب ادخال رقم هاتف صحيح',

      ],



      passwordRules: [
        v => !!v || 'يجب ادخال الباسورد',
        (v) => v.length >= 8 || 'يجب ان لايقل الباسورد عن ٨ احرف او ارقام',
        (v) => /^.*(?=.{3,})(?=.*[a-zA-Z])/.test(v) || 'يجب ان يحتوي على حروف وارقام'
      ],
      passwordConfirmationRules: [
        v => !!v || 'يجب ادخال الباسورد',
        (v) => v.length >= 8 || 'يجب ان لايقل الباسورد عن ٨ احرف او ارقام',
        (v) => /^.*(?=.{3,})(?=.*[a-zA-Z])/.test(v) || 'يجب ان يحتوي على حروف وارقام',

      ],


      emailRules: [
        //  v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'الايميل غير صحيح',
      ],

      errors: '',
      verfy_code: '',
      user: {
        phone: '',
        reg_dialog: false,
        password: '',
      },



      options: {
        isLoggingIn: true,
        shouldStayLoggedIn: true,
      },

    }),

    mounted() {
      this.getCategory();
    },


    methods: {
      scrollToTop() {
        window.scrollTo(0, 0);
      },
      submitـsignup() {
          if (this.$refs.form_signup.validate()) {

            var data = {

              full_name: this.owners.name,
              password: this.owners.password,
              password_confirmation: this.owners.password_confirmation,
              user_phone: "964" + parseInt(this.owners.phone),
              owner_brand_name: this.owners.company_name,
              owner_phone: "964" + parseInt(this.owners.phone),
              category_id: this.owners.category_id.id,
              images:[],



            };
            this.loading = true;

            this.$http({
                method: 'post',
                url: "/auth/v2/signUpOthers",
                data: data,
              })
              .then(response => {
                response,
             
                this.token = response.data.token;
                localstorageservice.setToken(this.token);
              
                this.is_signup=false;
                this.verfy_Dailog = true;

                this.signin = true;



              })
              .catch(error => {
                if (error.response) {

                  this.error_msg = [];
                  if (error.response.data.data.password ==
                    "The password confirmation does not match.") {
                    this.scrollToTop();
                    this.error_msg.push('الباسوردان غير متاطبقان');

                  }

                  if (error.response.data.data.user_phone ==
                    "The user phone has already been taken.") {
                    this.scrollToTop();
                    this.error_msg.push('رقم الهاتف مسجل');

                  }



                }


              }).finally(() => {

                this.loading = false;
              });

          }

       // }

      },

      verfyAccount() {
        if (this.$refs.active_form.validate()) {

          this.$http({
              method: 'get',
              url: '/auth/v2/accountActivate/' + this.verfy_code,          

            }).then(response => {
              if (response.data.success == true) {

                //this.$swal('', "شكرا تم التسجيل بنجاح",'success');
                 this.$swal('', "شكرا تم التسجيل بنجاح ",
                  'success')
                 this.login_info = response.data.data;
                   this.$cookies.set('owner_info', this.owner_info);
                  this.$user.set({
                    role: OWNER_ROLE
                  });

                 this.verfy_Dailog = false;
                  EventBus.$emit('changeUserInfo', true);
                  this.owner_info.role = this.$user.get().role;
                  this.owner_info.id = this.login_info.id;
                  this.owner_info.name = this.login_info.user_name;
                  this.owner_info.phone = this.login_info.user_phone;
                  this.owner_info.email = this.login_info.user_email;
                  this.owner_info.photo = this.login_info.user_image;
                   this.owner_info.item_num = this.login_info.item_num;
                  this.$cookies.set('owner_info', this.owner_info);

                 if (this.owner_info.item_num==0) {
                
                    this.$router.push({
                      name: "AddNewItem"
                    })

                  } else if (this.owner_info.item_num > 0) {
                  
                    this.$router.push({
                      name: "OwnerDashbourd"
                    })

                  }
                  localstorageservice.setToken(this.login_info.token);

                this.verfy_Dailog = false;

              }

            })
            .catch(error => {
              if (error.response.data.success == false) {
                this.$swal('', 'رمز التفعيل خطاء', 'error')
              }

            })


        }
      },
      getCategory() {

        var url = "/v2/Categories";
        axios.get(url).then(response => {
          this.categories = response.data.data;



        })
      },


      checkactive() {
        // alert('stsr');



      },
      pickFile(index) {

        this.img_cliced = index
        this.$refs.image.click()

      },

      onFilePicked(e) {

        const files = e.target.files
        if (files[0] !== undefined) {
          this.imageName = files[0].name
          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }


          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.imageUrl = fr.result
            this.imageFile = files[0]
            this.images[this.img_cliced] = fr.result;
            var a = this.img_cliced + 1;
            this.img_name = "   صورة رقم " + a



          })

        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''
        }
      },


      delete_img(img_id, index) {


        this.user_info = this.$cookies.get('admin_info');


        const Swal = require('sweetalert2');



        Swal.fire({
          title: "هل انت متاكد من الحذف ؟",

          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'

        }).then((result) => {
          if (result.value) {
            this.img_cliced = index;


            this.editedItem.images[index].image_url = 'a';
            this.img_name = index;
            this.images[index] = 'a';

            var url = "/v2/items/delete_image/" + img_id;
            axios({
              method: 'post',
              url: url,
              headers: {

              }

            }).then(response => {

              response,
              this.editedItem.images[index].image_url = 'a';
              //not important
              this.img_name = index;
              this.images[index] = 'a';

              Swal.fire(
                'تم الحذف بنجاح',
                '',
                'success'
              )

            }).catch(error => {
              error
              //     this.$swal('خطاء', "خطاء بالاتصال", 'error')
            }).finally(d => {
              d,

              this.getitems();
            });



          }
        })




      },




    },
    components: {

    }
  }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>